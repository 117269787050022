import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, onAuthStateChanged, NextOrObserver, User, signOut } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import {
  getStorage,
  ref as storageRef,
  StorageReference,
  uploadBytes,
  UploadMetadata,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAlGTuVVeaDBpMgXsLoFwhi3WTIvonej5k",
  authDomain: "lawyer-96052.firebaseapp.com",
  databaseURL: "https://lawyer-96052.firebaseio.com",
  projectId: "lawyer-96052",
  storageBucket: "lawyer-96052.appspot.com",
  messagingSenderId: "306204896459",
  appId: "1:306204896459:web:52c0684ac6d3f65e61bf4e",
  measurementId: "G-TBFSXKS5JS",
};

class Firebase {
  private _app;
  private analytics;

  constructor() {
    this._app = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this._app);
  }

  public get app(): FirebaseApp {
    return this._app;
  }

  public auth() {
    const auth = getAuth();

    return {
      ...auth,
      signInWithCustomToken: (token: string) => signInWithCustomToken(auth, token),
      onAuthStateChanged: (fun: NextOrObserver<User>) => onAuthStateChanged(auth, fun),
      signOut: () => signOut(auth),
    };
  }

  public storage() {
    const storage = getStorage();

    return {
      ...storage,
      ref: (ref: string) => storageRef(storage, ref),
      uploadBytes: (ref: StorageReference, file: Blob | Uint8Array | ArrayBuffer, metadata?: UploadMetadata) =>
        uploadBytes(ref, file, metadata),
      upload: (ref: string, file: Blob | Uint8Array | ArrayBuffer) => uploadBytes(this.storage().ref(ref), file),
      deleteObject: (ref: StorageReference) => deleteObject(ref),
      delete: (ref: string) => deleteObject(this.storage().ref(ref)),
      getDownloadURL: (ref: StorageReference) => getDownloadURL(ref),
    };
  }
}

export default new Firebase();

import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "../../components/forms/Button";
import FormInput from "../../components/forms/FormInput";
import Firebase from "../../configs/Firebase";
import useFetch from "../../hooks/useFetch";
import styles from "./Login.module.scss";

function Login() {
  const [loading, execute] = useFetch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const submit = async () => {
    console.log("submit", email, password);

    if (!email || !password) {
      setError("خطأ في البريد الإلكتروني أو كلمة المرور");
      return;
    }
    if (email !== "abuba413@gmail.com" && email !== "lawyermh17@gmail.com") {
      setError("خطأ في البريد الإلكتروني أو كلمة المرور");
      return;
    }

    setError("");
    execute({
      method: "POST",
      endPoint: "/users/login",
      body: { credential: email, password },
    }).then((res) => {
      console.log("res", res);

      // Wrong Email or password
      if (res.success === false) {
        setError("خطأ في البريد الإلكتروني أو كلمة المرور");
        return;
      }

      Firebase.auth().signInWithCustomToken(res.token);
    });
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form}>
        <img src="/logo.png" alt="logo" className={styles.logo} />

        <FormInput
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          title="البريد الإلكتروني"
        />
        <FormInput
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          title="كلمة المرور"
          type="password"
        />
        {error && <p>{error}</p>}
        <Button className={styles.submit} onClick={submit} disabled={loading}>
          تسجيل الدخول
        </Button>
      </Form>
    </div>
  );
}

export default Login;

import { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import styles from "./App.module.scss";

import NavBar from "./components/nav-bar/Navbar";
import AddReport from "./pages/AddReport/AddReport";
import Login from "./pages/Login/Login";
import Articles from "./pages/Articles/Articles";
import AddArticle from "./pages/Articles/AddArticle/AddArticle";
import Firebase from "./configs/Firebase";
import Proofs from "./pages/Proofs/Proofs";
import AD from "./pages/AD/AD";

function App() {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    Firebase.auth().onAuthStateChanged((user) => {
      console.log("user", user);

      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, [setUser]);

  if (user === null)
    return (
      <div className={styles.container}>
        <Login />
      </div>
    );

  return (
    <div className={styles.container}>
      <NavBar />

      <Routes>
        <Route path="/articles">
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/add" element={<AddArticle />} />
        </Route>
        <Route path="/proofs" element={<Proofs />} />
        <Route path="/AD" element={<AD />} />
        <Route path="/add-report" element={<AddReport />} />

        <Route path="*" element={<Navigate to="/articles" replace />} />
      </Routes>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Button from "../../components/forms/Button";
import FormInput from "../../components/forms/FormInput";
import useFetch from "../../hooks/useFetch";
import type { Proofs as ProofsType } from "../../types";
import styles from "./Proofs.module.scss";

function Proofs() {
  const [loadingProofs, executeProofs] = useFetch();

  const [loadingSubmit, executeSubmit] = useFetch();
  const [err, setErr] = useState({ cases: "", consultation: "", sessions: "", training: "" });
  const [proofs, setProofs] = useState<ProofsType>({ cases: "", consultation: "", sessions: "", training: "" });

  useEffect(() => {
    executeProofs({ endPoint: "/client/homeProof" }).then((res) => {
      setProofs(res.data);
    });
  }, [executeProofs]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setProofs((prev) => ({ ...prev, [id]: value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loadingSubmit) {
      return;
    }

    const { errors, isValid } = validateProofs(proofs);
    setErr(errors);
    if (!isValid) {
      return;
    }

    executeSubmit({ endPoint: "/admin/homeProof", method: "POST", body: proofs })
      .then(() => {
        Swal.fire("تمت الإضافة!", "تم تعديل البيانات بنجاح", "success");
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  if (loadingProofs) {
    return <>loading ...</>;
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onSubmit}>
        <h1>تعديل الإحصائيات</h1>

        <FormInput required onChange={handleChange} value={proofs.cases} error={err.cases} id="cases" title="القضايا" />
        <FormInput required onChange={handleChange} value={proofs.sessions} error={err.sessions} id="sessions" title="الجلسات المنجزة" />
        <FormInput required onChange={handleChange} value={proofs.training} error={err.training} id="training" title="التدريب" />
        <FormInput
          required
          onChange={handleChange}
          value={proofs.consultation}
          error={err.consultation}
          id="consultation"
          title="الاستشارات"
        />

        <Button type="submit" disabled={loadingSubmit}>
          تعديل
        </Button>
      </Form>
    </div>
  );
}

export default Proofs;

function validateProofs(proofs: any) {
  let isValid = true;
  const errors: any = { cases: "", consultation: "", sessions: "", training: "" };

  for (const key in proofs) {
    if (Object.prototype.hasOwnProperty.call(proofs, key)) {
      const proof = proofs[key];
      if (proof.length < 1) {
        errors[key] = "هذا الحقل مطلوب";
        isValid = false;
      } else if (proof.length > 5) {
        errors[key] = "بحد اقصى ٥ حروف و ارقام";
        isValid = false;
      }
    }
  }

  return { errors, isValid };
}

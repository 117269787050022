import Swal from "sweetalert2";
import Button from "../../components/forms/Button";
import styles from "./Articles.module.scss";
import useFetch from "../../hooks/useFetch";

const DeleteArticleButton = ({ articleId, loadData }: { articleId: number; loadData: () => void }) => {
  const [loadingDelete, executeDelete] = useFetch();

  const deleteArticle = () => {
    executeDelete({ endPoint: `/admin/articles/${articleId}`, method: "DELETE" })
      .then((res) => {
        if (res.success === false) {
          Swal.fire(
            "حدث خطأ",
            "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
            "error",
          );
          return;
        }
        Swal.fire("تم الحذف!", "تم حذف المقال بنجاح", "success");
        loadData();
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  return (
    <Button className={styles.button} disabled={loadingDelete} onClick={deleteArticle}>
      حذف
    </Button>
  );
};

export default DeleteArticleButton;

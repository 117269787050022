import { Form, FormControlProps } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import styles from "./FormInput.module.scss";

export type FormInputProps = FormControlProps & {
  title?: string;
  required?: boolean;
  search?: {
    items: string[] | { title: string; value: string }[];
    selected?: string;
    loading?: boolean;
    onClick: (v: string) => void;
  };
  onChangeSelect?: React.ChangeEventHandler<HTMLSelectElement>;
  error?: string;
  onChangeFile?: React.ChangeEventHandler<HTMLInputElement>;
};

const FormInput = ({ title, type, children, search, required, onChangeSelect, error, onChangeFile, ...props }: FormInputProps) => {
  if (type === "select") {
    return (
      <div className={styles.input}>
        {title && <Form.Label className={`${required ? styles.required : ""}`}>{title}</Form.Label>}
        <Form.Select value={props.value} onChange={onChangeSelect}>
          {children}
        </Form.Select>
      </div>
    );
  } else if (type === "search" && search) {
    return (
      <Form.Group className={styles.input}>
        {title && <Form.Label className={`${required ? styles.required : ""}`}>{title}</Form.Label>}
        <Form.Control type={type} placeholder={title} {...props} />

        <div className={styles.search}>
          {search.loading && <p>Loading...</p>}
          {search.items.map((v, i) => {
            if (typeof v === "string") {
              return (
                <div
                  key={`search-${i}-${v}`}
                  className={`${styles.item} ${search.selected === v ? styles.active : ""}`}
                  onClick={() => search.onClick(v)}
                >
                  {v}
                </div>
              );
            }

            return (
              <div
                key={`search-${i}-${v.value}`}
                className={`${styles.item} ${search.selected === v.value ? styles.active : ""}`}
                onClick={() => search.onClick(v.value)}
              >
                {v.title}
              </div>
            );
          })}
        </div>
      </Form.Group>
    );
  }

  if (type === "pdf" || type === "image") {
    return (
      <Form.Group className={styles.input}>
        {title && <Form.Label className={`${required ? styles.required : ""}`}>{title}</Form.Label>}
        <Form.Control
          placeholder={title}
          isInvalid={!!error}
          type="file"
          accept={type === "pdf" ? ".pdf" : ".png,.jpg"}
          onChange={onChangeFile}
          {...props}
        />
        <Feedback type="invalid">{error}</Feedback>
      </Form.Group>
    );
  }

  return (
    <Form.Group className={styles.input}>
      {title && <Form.Label className={`${required ? styles.required : ""}`}>{title}</Form.Label>}
      <Form.Control placeholder={title} isInvalid={!!error} type={type} {...props} />
      <Feedback type="invalid">{error}</Feedback>
    </Form.Group>
  );
};

export default FormInput;

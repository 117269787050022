import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Button from "../../components/forms/Button";
import FormInput from "../../components/forms/FormInput";
import useFetch from "../../hooks/useFetch";
import styles from "./AddReport.module.scss";
import type { Case, Client, Report } from "../../types";

function AddReport() {
  const [client, setClient] = useState<string>("");
  const [loadingClients, executeClients, clientsData] = useFetch();
  const clients: Client[] | undefined = clientsData?.data;

  const [, executeCases, casesData] = useFetch();
  const cases: Case[] | undefined = casesData?.data;

  const [loadingSubmit, executeSubmit] = useFetch();
  const [err, setErr] = useState({ reportDate: "", nextDate: "", details: "", revision: "", followUpDate: "", notes: "" });

  const [report, setReport] = useState<Report>({
    caseId: 0,
    followUpDate: "",
    nextDate: "",
    reportDate: "",
    revision: "",
    details: "",
    notes: "",
  });

  useEffect(() => {
    executeClients({ endPoint: "/users/getAllUsers" });
  }, [executeClients]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    executeClients({ endPoint: `/users/search/${value}` });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setReport((prev) => ({ ...prev, [id]: value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loadingSubmit) {
      return;
    }

    const { errors, isValid } = validateReport(report);
    setErr(errors);
    if (!isValid) {
      return;
    }

    executeSubmit({ endPoint: "/reports/createReport", method: "POST", body: report })
      .then(() => {
        Swal.fire("تمت الأضافة!", "تم إضافة التقرير بنجاح", "success");
        setReport({ caseId: 0, followUpDate: "", nextDate: "", reportDate: "", revision: "", details: "", notes: "" });
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onSubmit}>
        <h1>إضافة تقرير</h1>

        {/* Search clients */}
        <FormInput
          required
          title="العميل"
          placeholder="ابحث عن عميل"
          type="search"
          onChange={onSearch}
          search={{
            onClick: (value: string) => {
              executeCases({ endPoint: `/cases/userCases/${value}` });
              setReport({ caseId: 0, followUpDate: "", nextDate: "", reportDate: "", revision: "", details: "", notes: "" });
              setClient(value);
            },
            selected: client,
            loading: loadingClients,
            items: clients?.map(formatClient) || [],
          }}
        />

        {/* Cases */}
        {cases && (
          <FormInput
            required
            onChangeSelect={(e) => setReport((prev) => ({ ...prev, caseId: Number(e.target.value) }))}
            title="القضية"
            type="select"
            value={report.caseId}
          >
            <option value={0}>أختر القضية</option>
            {cases?.map((v) => (
              <option value={v.caseId}>{v.caseName}</option>
            ))}
          </FormInput>
        )}

        {/* Report data */}
        {report.caseId > 0 && (
          <>
            <FormInput required onChange={handleChange} error={err.reportDate} id="reportDate" title="تاريخ التقرير" type="date" />
            <FormInput required onChange={handleChange} error={err.nextDate} id="nextDate" title="تاريخ الجلسة القادمة" type="date" />
            <FormInput onChange={handleChange} id="followUpDate" title="تاريخ المتابعة" type="date" />
            <FormInput required onChange={handleChange} error={err.revision} id="revision" title="جهة المراجعة" />
            <FormInput required onChange={handleChange} error={err.details} id="details" title="التقرير" as="textarea" />
            <FormInput onChange={handleChange} id="notes" title="الملاحظات" as="textarea" />

            <Button type="submit" disabled={loadingSubmit}>
              إضافة
            </Button>
          </>
        )}
      </Form>
    </div>
  );
}

export default AddReport;

const formatClient = (v: Client) => ({ title: `${v.title} / ${v.userName} - ${v.phone}`, value: v.userId });

function validateReport(report: Report) {
  let isValid = true;
  const errors = { reportDate: "", nextDate: "", details: "", revision: "", followUpDate: "", notes: "" };

  const { reportDate, nextDate, details, revision } = report;

  if (!reportDate) {
    errors.reportDate = "برجاء تحديد تاريخ التقرير";
    isValid = false;
  }

  if (!nextDate) {
    errors.nextDate = "برجاء تحديد تاريخ الجلسة القادمة";
    isValid = false;
  }

  if (!revision) {
    errors.revision = "برجاء كتابة جهة المراجعة";
    isValid = false;
  } else if (revision && revision.length > 20) {
    errors.revision = "يجب ان تكون جهة المراجعة ٢٠ حرف بحد اقصى";
    isValid = false;
  }

  if (!details) {
    errors.details = "برجاء كتابة التقرير";
    isValid = false;
  } else if (details && details.length > 5000) {
    errors.details = "يجب ان يكون التقرير ٥٠٠٠ حرف بحد اقصى";
    isValid = false;
  }

  return { errors, isValid };
}

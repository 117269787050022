import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import moment from "moment";
import "moment/locale/ar";
import Button from "../../components/forms/Button";
import styles from "./Articles.module.scss";
import useFetch from "../../hooks/useFetch";
import { Article } from "../../types/Article";
import DeleteArticleButton from "./DeleteArticleButton";

function Articles() {
  const navigate = useNavigate();
  const [loading, execute, data] = useFetch();
  const articles: Article[] = data?.data?.results || [];

  const loadData = useCallback(() => {
    execute({ endPoint: "/admin/articles/" });
  }, [execute]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>المقالات</h2>
        <Button onClick={() => navigate("/articles/add")}>مقال جديد</Button>
      </div>

      <Table responsive hover>
        {/* Header */}
        <thead>
          <tr>
            <th>#</th>
            <th>العنوان</th>
            <th>وقت النشر</th>
            <th></th>
          </tr>
        </thead>

        {/* Body */}
        <tbody>
          {articles.map((v) => (
            <tr key={`article-${v.articleId}`} className={styles.row}>
              <td>{v.articleId}</td>
              <td>{v.title}</td>
              <td>{moment(v.createdAt).locale("ar").format("dddd DD MMMM YYYY - h:mmA")}</td>

              <td>
                <Button className={styles.button} onClick={() => window.open(`https://almuhayili.com/articles/${v.articleId}`)}>
                  فتح
                </Button>
              </td>

              <td>
                <DeleteArticleButton articleId={v.articleId} loadData={loadData} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Articles;

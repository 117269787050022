import { Navbar } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";

import styles from "./Navbar.module.scss";
import { useState } from "react";
import Firebase from "../../configs/Firebase";

type RouteProps = {
  route: string;
  title: string;
};

const NavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  const isCurrentPath = (path: string) => (pathname === path ? styles.active : undefined);

  const Route = ({ route, title }: RouteProps) => {
    return (
      <li>
        <Link to={route}>
          <p className={isCurrentPath(route)}>{title}</p>
        </Link>
      </li>
    );
  };

  return (
    <Navbar expanded={expanded} expand="md" className={styles.nav} collapseOnSelect>
      <div className={styles.navContainer}>
        {/* Title */}
        <div className={styles.title}>
          <Link to="/">
            <h1>شركة المهايلي للمحاماه</h1>
          </Link>
        </div>

        {/* Mobile toggle */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" className={styles["toggle-btn"]} onClick={() => setExpanded((p) => !p)}>
          <SVG src="/assets/hamburger.svg" />
        </Navbar.Toggle>

        <Navbar.Collapse id="basic-navbar-nav" className={styles["navbar-collapse"]}>
          {/* Links */}
          <ul>
            <Route route="/articles" title="المقالات" />
            <Route route="/proofs" title="تعديل الإحصائيات" />
            <Route route="/AD" title="الاعلان" />
            <Route route="/add-report" title="أضافه تقرير" />
            <li>
              <a href="/" onClick={() => Firebase.auth().signOut()}>
                <p>تسجيل الخروج</p>
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavBar;

import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import Button from "../../components/forms/Button";
import FormInput from "../../components/forms/FormInput";
import useFetch from "../../hooks/useFetch";
import styles from "./AD.module.scss";

function AD() {
  const [loadingProofs, executeProofs] = useFetch();

  const [loadingSubmit, executeSubmit] = useFetch();
  const [loadingDelete, executeDelete] = useFetch();
  const [err, setErr] = useState({ title: "", description: "" });
  const [data, setData] = useState({ title: "", description: "" });

  useEffect(() => {
    executeProofs({ endPoint: "/client/homeAds" }).then((res) => {
      const { title, description } = res.data;
      setData({ title, description });
    });
  }, [executeProofs]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setData((prev) => ({ ...prev, [id]: value }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loadingSubmit || loadingDelete) {
      return;
    }

    const { errors, isValid } = validateInputs(data);
    setErr(errors);
    if (!isValid) {
      return;
    }

    executeSubmit({ endPoint: "/admin/homeADs", method: "POST", body: data })
      .then(() => {
        Swal.fire("تمت الإضافة!", "تم تعديل البيانات بنجاح", "success");
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  const onClickDelete = () => {
    if (loadingSubmit || loadingDelete) {
      return;
    }

    executeDelete({ endPoint: "/admin/homeADs", method: "DELETE" })
      .then(() => {
        Swal.fire("تمت المسح!", "تم حذف الاعلان بنجاح", "success");
        setData({ title: "", description: "" });
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  if (loadingProofs) {
    return <>loading ...</>;
  }

  return (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onSubmit}>
        <h1>تعديل الإحصائيات</h1>

        <FormInput required onChange={handleChange} value={data.title} error={err.title} id="title" title="العنوان" />
        <FormInput
          required
          onChange={handleChange}
          value={data.description}
          error={err.description}
          id="description"
          title="الوصف"
          as="textarea"
        />

        <div className={styles.buttons}>
          <Button type="submit" disabled={loadingSubmit || loadingDelete}>
            تعديل
          </Button>

          <Button type="reset" className={styles.clear} disabled={loadingSubmit || loadingDelete} onClick={onClickDelete}>
            مسح
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AD;

function validateInputs(data: any) {
  const { title, description } = data;
  let isValid = true;
  const errors: any = { title: "", description: "" };

  if (title.length < 1) {
    errors.title = "هذا الحقل مطلوب";
    isValid = false;
  } else if (title.length > 30) {
    errors.title = "بحد اقصى ٣٠ حروف و ارقام";
    isValid = false;
  }

  if (description.length < 1) {
    errors.description = "هذا الحقل مطلوب";
    isValid = false;
  } else if (description.length > 128) {
    errors.description = "بحد اقصى ١٢٨ حروف و ارقام";
    isValid = false;
  }

  return { errors, isValid };
}

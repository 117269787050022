import React, { useState } from "react";

export type UserType = {
  uid: string;
} | null;

export const UserContext = React.createContext<{
  user: UserType;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
}>({ user: null, setUser: (v) => {} });

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<UserType>(null);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export default UserProvider;

import { useCallback, useState } from "react";
import Firebase from "../configs/Firebase";

//  API Backend base url
const baseApiUrl = "https://lawyer2020.herokuapp.com";
// const baseApiUrl = "http://localhost:3001";

// Default Headers containing headers to be set by default to any request unless it overwritten
const defaultHeaders = { Accept: "application/json", "Content-Type": "application/json" };

type methodsType = "GET" | "POST" | "PUT" | "DELETE";
type bodyType = { [key: string]: any };
type objectStringsType = { [key: string]: string };
type executeType = {
  endPoint: string;
  body?: bodyType;
  method?: methodsType;
  headers?: objectStringsType;
};
type useFetchType = [
  loading: boolean,
  execute: (data: executeType) => Promise<any>,
  data: any,
  error: objectStringsType | string | undefined,
];

function useFetch(): useFetchType {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<objectStringsType>();

  const execute = useCallback(async (data: executeType): Promise<any> => {
    const { endPoint, body, headers, method = "GET" } = data;
    setLoading(true);
    setData(undefined);
    setError(undefined);

    const finalUrl = `${baseApiUrl}${endPoint}`;
    const finalHeaders: any = { ...defaultHeaders, ...headers };

    const user = Firebase.auth().currentUser;

    if (user) {
      finalHeaders["auth-token"] = await user.getIdToken();
    }

    return new Promise((resolve, reject) => {
      fetchData(finalUrl, body, method, finalHeaders)
        .then((d: any) => {
          setData(d);
          resolve(d);
          setLoading(false);
          return;
        })
        .catch((err) => {
          setError(err);
          reject(err);
          setLoading(false);
          return;
        });
    });
  }, []);

  return [loading, execute, data, error];
}

export function fetchData(url: string, body?: bodyType, method: methodsType = "GET", headers: objectStringsType = {}): Promise<any> {
  const stringifyBody = body ? JSON.stringify(body) : undefined;

  return new Promise((resolve, reject) => {
    fetch(url, { method, headers, body: stringifyBody })
      .then((res) => res.json())
      .then((res) => resolve(res))
      .then((err) => reject(err));
  });
}

export default useFetch;

import moment from "moment";
import { useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../components/forms/Button";
import FormInput, { FormInputProps } from "../../../components/forms/FormInput";
import Firebase from "../../../configs/Firebase";
import useFetch from "../../../hooks/useFetch";
import styles from "./AddArticle.module.scss";

type ItemTypes = "H" | "P" | "I" | "F";
type ItemType = { type: ItemTypes; value: string | File | null };

function AddArticle() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [elements, setElements] = useState<ItemType[]>([]);
  const [loadingSubmit, executeSubmit] = useFetch();

  const addElement = (type: ItemTypes) => setElements((prev) => [...prev, { type, value: "" }]);
  const removeElement = (index: number) => setElements((prev) => [...prev.filter((_, i) => i !== index)]);
  const handleChange = (i: number, value: string | File | null) =>
    setElements((prev) => {
      const n = [...prev];
      n[i].value = value;
      return n;
    });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loadingSubmit) return;

    if (!validateSubmit(title, description, elements)) {
      return;
    }

    // To filter elements and upload the files to storag
    const submitElements: { type: ItemTypes; value: string }[] = [];
    const date = moment().locale("en").format("YYYY-MM-DD");

    for await (const element of elements) {
      const { type, value } = element;

      // Push the headers and paragraphs directly
      if (type === "H" || type === "P") {
        submitElements.push({ type, value: value as string });
        continue;
      }

      // Upload the images and PDF files to storage then push their download url to elements
      const result = await Firebase.storage().upload(`articles/${date}/${uuidv4()}`, value as File);
      const downloadUrl = await Firebase.storage().getDownloadURL(result.ref);
      submitElements.push({ type, value: downloadUrl });
    }

    // Submit data
    executeSubmit({ endPoint: "/admin/articles/", method: "POST", body: { title, description, elements: submitElements } })
      .then((res) => {
        if (res.success === false) {
          Swal.fire(
            "حدث خطأ",
            "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
            "error",
          );
          return;
        }
        Swal.fire("تمت الأضافة!", "تم إضافة المقال بنجاح", "success");
        setTitle("");
        setDescription("");
        setElements([]);
      })
      .catch(() => {
        Swal.fire(
          "حدث خطأ",
          "يرجى التحقق من الاتصال الخاص بك والمحاولة مرة أخرى ، في حالة تكرار نفس الخطأ ، اتصل بمزود الخدمة الخاص بك",
          "error",
        );
      });
  };

  return (
    <div className={styles.container}>
      <Form className={styles.form} onSubmit={onSubmit}>
        <h1>إضافة مقال</h1>

        <FormInput
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          error={validateItem(title)}
          title="العنوان"
          placeholder="عنوان المقال"
        />
        <FormInput
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          error={validateItem(description, "D")}
          as="textarea"
          title="الملخص"
          placeholder="ملخص المقال"
        />

        <h3>محتويات المقال</h3>
        {elements.map((item, i) => {
          if (item.type === "P")
            return (
              <InputWithRemove
                value={item.value as string}
                onChange={(e) => handleChange(i, e.target.value)}
                required
                as="textarea"
                placeholder="محتوى"
                error={validateItem(item.value as string, "P")}
                onRemove={() => removeElement(i)}
              />
            );
          if (item.type === "I")
            return (
              <InputWithRemove
                onChangeFile={(e) => handleChange(i, e.currentTarget.files?.item(0) || null)}
                error={validateFile(item.value)}
                type="image"
                onRemove={() => removeElement(i)}
              />
            );

          if (item.type === "H")
            return (
              <InputWithRemove
                value={item.value as string}
                onChange={(e) => handleChange(i, e.target.value)}
                placeholder="عنوان"
                error={validateItem(item.value as string)}
                onRemove={() => removeElement(i)}
              />
            );

          return <></>;
        })}

        {/* Buttons */}

        <div className={styles.buttons}>
          <Button onClick={() => addElement("H")}>إضافة عنوان</Button>
          <Button onClick={() => addElement("P")}>إضافة محتوى</Button>
          <Button onClick={() => addElement("I")}>إضافة صورة</Button>
        </div>

        {/* Files */}
        <h3>الملفات المرفقة</h3>
        {elements.map(
          (item, i) =>
            item.type === "F" && (
              <InputWithRemove
                onChangeFile={(e) => handleChange(i, e.currentTarget.files?.item(0) || null)}
                error={validateFile(item.value)}
                type="pdf"
                placeholder="عنوان"
                onRemove={() => removeElement(i)}
              />
            ),
        )}
        <Button onClick={() => addElement("F")}>إضافة ملف</Button>

        <Button className={styles.submit} type="submit" disabled={loadingSubmit}>
          نشر المقال
        </Button>
      </Form>
    </div>
  );
}

export default AddArticle;

type inputProps = {
  onRemove: () => void;
} & FormInputProps;

const InputWithRemove = ({ onRemove, ...props }: inputProps) => {
  return (
    <div className={styles.inputWithRemove}>
      <FormInput {...props} />
      <img onClick={onRemove} src="/assets/trash.png" alt="delete" />
    </div>
  );
};

function validateItem(value: string, type?: ItemTypes | "D") {
  let min = 3,
    max = 60;

  let error = "";

  if (type === "P") {
    min = 10;
    max = 1000;
  } else if (type === "D") {
    min = 10;
    max = 160;
  }

  if (!value) {
    error = `هذا الحقل مطلوب`;
  } else if (value.length < min) {
    error = `يجب ان يكون هذا الحقل ${min} حرف بحد ادنى`;
  } else if (value.length > max) {
    error = `يجب ان يكون هذا الحقل ${max} حرف بحد اقصى`;
  }

  return error;
}

function validateFile(value: string | File | null) {
  let error = "";

  if (!value) {
    error = `هذا الحقل مطلوب`;
  }

  return error;
}

function validateSubmit(title: string, description: string, elements: ItemType[]) {
  if (validateItem(title)) return false;

  if (validateItem(description, "D")) return false;

  elements.forEach((element) => {
    if (element.type === "F" || element.type === "I") {
      if (validateFile(element.value)) return false;
    } else {
      if (validateItem(element.value as string, element.type)) return false;
    }
  });

  return true;
}
